<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useCompanyStore } from '@/stores/companies'
import type { CompanyResponse } from '~/models/apiCore'
import type { actions, domains } from '@/models/rbac'
import { useNotificationStore } from '~/stores/pushNotifications'
const { $auth, $userCan, $WSDisconnect, $getSystemRole, $getTrueSystemRole } =
  useNuxtApp()
const route = useRouter()
const userStore = useUserStore()
const companiesStore = useCompanyStore()
const { currentCompany } = storeToRefs(companiesStore)

const selectedCompany: Ref<number | undefined> = ref(
  companiesStore.currentCompany ? companiesStore.currentCompany.Id : 0,
)

const pushStore = useNotificationStore()

const logout = async () => {
  pushStore.setAutoReconnect(false)
  $WSDisconnect()
  userStore.purge()
  companiesStore.purge()
  await $auth.amplify.signOut()
  navigateTo('/login')
}
const settingsPermissions = () => {
  return (
    $userCan('read', 'campaign/type') ||
    $userCan('read', 'products') ||
    $userCan('read', 'brand')
  )
}
const switchCompany = () => {
  const id = selectedCompany.value
  if (companiesStore.companiesList) {
    const company = companiesStore.companiesList.find(
      (item: CompanyResponse) => {
        return item.Id === id
      },
    )
    if (company) {
      companiesStore.setCurrentCompany(company)
      currentCompany.value = company

      try {
        const routeNav = route.currentRoute.value
        const action = routeNav.meta.allowedAction as actions
        const domain = routeNav.meta.allowedDomain as domains

        if (routeNav.params.companyId) {
          if ($userCan(action, domain, id)) {
            const pathSegments = routeNav.fullPath.split('/')
            // Check if there are more segments beyond /company/:companyId
            if (pathSegments.length > 4) {
              const newPath = `/company/${id}/${pathSegments[3]}/`
              route.replace({ path: newPath })
            } else {
              route.replace({ params: { companyId: id } })
            }
          } else {
            navigateTo('/')
          }
        } else if ($userCan(action, domain)) {
          navigateTo(routeNav.fullPath)
        } else {
          navigateTo('/')
        }
      } catch {
        navigateTo('/')
      }
    }
  }
}
</script>

<template>
  <header class="navbar px-5 bg-accent">
    <div class="container">
      <div class="flex flex-1">
        <NuxtLink
          class="btn btn-ghost text-white px-3 normal-case text-xl pl-0"
          to="/"
        >
          <img src="/logo.png" alt="The Mailworks" class="max-w-full h-full" />
        </NuxtLink>
        <ul
          tabindex="0"
          class="flex items-center w-full border-l border-base-200 ml-5 pl-5"
        >
          <li>
            <NuxtLink to="/" class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="ion:home"
                class="w-6 h-6 mr-0.5"
              />Dashboard</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() === 'client_user' &&
              $userCan('read', 'company/campaign', currentCompany?.Id) &&
              $config.public.environment !== 'production'
            "
          >
            <NuxtLink
              :to="`/company/${currentCompany?.Id}/campaigns`"
              class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="gridicons:multiple-users"
                class="w-6 h-6 mr-0.5"
              />Campaigns</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() === 'client_user' &&
              $userCan('read', 'company/list', currentCompany?.Id)
            "
          >
            <NuxtLink
              :to="`/company/${currentCompany?.Id}/lists`"
              class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="mdi:list-box"
                class="w-6 h-6 mr-0.5"
              />Lists</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() === 'client_user' &&
              $userCan('read', 'company/location/contact', currentCompany?.Id)
            "
          >
            <NuxtLink
              :to="`/company/${currentCompany?.Id}`"
              class="nav btn btn-ghost text-white px-3"
              ><Icon name="ph:buildings-fill" class="w-6 h-6 mr-0.5" />My
              Company</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() === 'client_user' &&
              $userCan('read', 'campaign/support', currentCompany?.Id)
            "
          >
            <NuxtLink
              :to="`/company/${currentCompany?.Id}/campaignsupport`"
              class="nav btn btn-ghost text-white px-3"
              ><Icon name="la:hands-helping" class="w-6 h-6 mr-0.5" />Campaign
              Support</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() !== 'client_user' &&
              $userCan('read', 'users/internal')
            "
          >
            <NuxtLink to="/users" class="nav btn btn-ghost text-white px-3"
              ><Icon name="gridicons:multiple-users" class="w-6 h-6 mr-0.5" />
              Users</NuxtLink
            >
          </li>
          <li
            v-else-if="
              $getSystemRole() !== 'client_user' &&
              $userCan('read', 'users/client')
            "
          >
            <NuxtLink
              to="/users/client"
              class="nav btn btn-ghost text-white px-3"
              ><Icon name="gridicons:multiple-users" class="w-6 h-6 mr-0.5" />
              Users</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() !== 'client_user' &&
              $userCan('read', 'company/location')
            "
          >
            <NuxtLink to="/company" class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="ph:buildings-fill"
                class="w-6 h-6 mr-0.5"
              />Companies</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() !== 'client_user' &&
              $userCan('read', 'accounting/invoices')
            "
          >
            <NuxtLink
              to="/accounting/invoice"
              class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="ph:scales-fill"
                class="w-6 h-6 mr-0.5"
              />Accounting</NuxtLink
            >
          </li>
          <li
            v-if="
              $getSystemRole() !== 'client_user' && $userCan('read', 'products')
            "
          >
            <NuxtLink to="/products" class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="bi:postcard-fill"
                class="w-6 h-6 mr-0.5"
              />Products</NuxtLink
            >
          </li>
          <li
            v-if="$getSystemRole() !== 'client_user' && settingsPermissions()"
          >
            <NuxtLink
              to="/settings/campaignType/"
              class="nav btn btn-ghost text-white px-3"
              ><Icon
                name="clarity:settings-solid"
                class="w-6 h-6 mr-0.5"
              />Settings</NuxtLink
            >
          </li>
        </ul>
      </div>
      <div class="flex items-center ml-5">
        <div
          v-if="
            $getTrueSystemRole() === 'client_user' &&
            companiesStore.companiesList &&
            companiesStore.companiesList.length > 1
          "
          class="mr-3"
        >
          <PrimeDropdown
            v-model="selectedCompany"
            :options="companiesStore.companiesList"
            option-label="CompanyName"
            option-value="Id"
            filter
            class="bg-white text-black"
            @change="switchCompany()"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ currentCompany?.CompanyName }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.CompanyName }}</div>
              </div>
            </template>
          </PrimeDropdown>
        </div>
        <div class="dropdown dropdown-end dropdown-dark">
          <div
            tabindex="0"
            role="button"
            class="nav btn btn-ghost btn-circle avatar"
          >
            <Avatar />
          </div>
          <ul tabindex="0" class="menu dropdown-content">
            <li>
              <NuxtLink to="/account">User Profile</NuxtLink>
            </li>
            <li>
              <button type="button" @click.prevent="logout()">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.router-link-active {
  color: #66ffe9;
}
</style>
